import './SpacerComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'

function SpacerComponent(props) {
  const { size, debug } = props

  return (
    <div className={['c-spacer', `c-spacer--${size}`, debug ? 'is-debug' : ''].join(' ')} />
  )
}
SpacerComponent.propTypes = {
  size: PropTypes.oneOf(['default', 'small', 'large']),
  debug: PropTypes.bool,
}
SpacerComponent.defaultProps = {
  size: 'default'
}

export default SpacerComponent
