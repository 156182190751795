import './AuthenticationLogoComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'

export default function AuthenticationLogoComponent(props) {
  const { src } = props

  return (
    <img className="c-authentication-logo" src={src} />
  )
}
AuthenticationLogoComponent.propTypes = {
  src: PropTypes.string.isRequired
}
