import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import FormComponent, { FormPropTypes } from 'components/FormComponent'
import { postRequest } from 'libs/requestsLib'
import { FlashContext } from 'contexts/FlashContext'

export default function FormInteractive(props) {
  const { path, onSuccess, inputs, defaultParams, forcedParams, multipart, type, onSubmit: externalOnSubmit, onChange: externalOnChange } = props
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { setFlash, closeFlash } = useContext(FlashContext)

  const onSubmit = (values) => {
    const finalParams = Object.assign({}, defaultParams, values, forcedParams)
    if (externalOnSubmit) return externalOnSubmit(finalParams)

    setLoading(true)
    postRequest(path, finalParams, multipart).then((response) => {
      setLoading(false)

      if (!response.result) {
        setErrorMessage(response.error || 'Si è verificato un errore')
        return
      }

      const flash = onSuccess(response)
      if (flash) setFlash(flash.title || 'Operazione completata', flash.message || null, flash.button || { label: 'Va bene', onClick: closeFlash }, flash.type || 'primary')
    })

  }

  const onChange = (values) => {
    externalOnChange && externalOnChange(values)

    let isValid = true
    inputs.forEach(input => {
      if (input.required && !values[input.name]) {
        isValid = false
      }
    })

    setValid(isValid)
    setErrorMessage('')
  }

  return (
    <FormComponent
      {...props}
      onSubmit={onSubmit}
      onChange={onChange}
      submitDisabled={!valid || loading}
      cancelDisabled={loading}
      errorMessage={errorMessage}
    />
  )
}
FormInteractive.propTypes = {
  ...{ ...FormPropTypes, onSubmit: PropTypes.any, errorMessage: PropTypes.any },
  path: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  defaultParams: PropTypes.object,
  forcedParams: PropTypes.object,
  multipart: PropTypes.bool,
  type: PropTypes.string,
}
FormInteractive.defaultParams = {
  defaultParams: {},
  forcedParams: {},
  type: 'primary',
}