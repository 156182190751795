import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { AppContext } from 'contexts/AppContext'

export default function LinkInteractive(props) {
  const { children, path, app, className } = props
  const { app: currentApp } = useContext(AppContext)
  const finalApp = app || currentApp

  if (currentApp == finalApp) {
    return <Link style={{ textDecoration: 'none' }} className={className} to={path}>{children}</Link>
  } else {
    return <a style={{ textDecoration: 'none' }} className={className} href={path}>{children}</a>
  }
}
LinkInteractive.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
  app: PropTypes.string,
  className: PropTypes.string
}
LinkInteractive.defaultProps = {
  onClick: () => {},
  path: '#'
}