import React, { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'

import FormInteractive from 'interactives/FormInteractive'
import LinkInteractive from 'interactives/LinkInteractive'

import ScreenComponent, { ScreenComponentItem } from 'components/ScreenComponent'
import CardComponent from 'components/CardComponent'
import AuthenticationLogoComponent from 'components/AuthenticationLogoComponent'
import { InputEmailComponent, InputPasswordComponent } from 'components/InputComponent'
import SpacerComponent from 'components/SpacerComponent'

export default function LoginRinascenteScreen(props) {
  const { views } = useContext(AppContext) 

  return (
    <ScreenComponent fullscreen center>
      <ScreenComponentItem size='full'>
        <AuthenticationLogoComponent src={views.logo_url} />
      </ScreenComponentItem>
      <ScreenComponentItem size='third'>
        <CardComponent>
          <FormInteractive
            path='/apis/authentication/login-phrase'
            inputs={[
              { name: 'auth_phrase', label: 'cognome.nome', component: InputPasswordComponent, required: true },
            ]}
            submitLabel='Accedi'
            onSuccess={() => window.location.assign('/')}
          />

          <SpacerComponent />

          <div style={{ fontSize: 12, textAlign: 'center' }}>
            Inserisci il tuo <b>cognome e nome in minuscolo separati da un punto</b><br />
            Esempio:<br />Niccolò Rossi accede con <i>rossi.niccolo</i><br />Luisa De Laurentis accede con <i>de.laurentis.luisa</i><br />Maria Luisa D'Amico accede con <i>damico.maria.luisa</i>
          </div>
        </CardComponent>
      </ScreenComponentItem>
    </ScreenComponent>
  )
}
