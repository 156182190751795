import './ScreenComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'

export default function ScreenComponent(props) {
  const { children, center, fullscreen, adaptive } = props

  return (
    <div className={["c-screen", center ? 'c-screen--center' : '', adaptive ? 'c-screen--adaptive' : '', fullscreen ? 'c-screen--fullscreen' : ''].join(' ')}>
      {children}
    </div>
  )
}
ScreenComponent.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
  fullscreen: PropTypes.bool,
  adaptive: PropTypes.bool
}

////////////////////////////////////////////////////////////////////////////////

export function ScreenComponentItem(props) {
  const { size, children } = props

  return (
    <div className={['c-screen__item', `c-screen__item--${size}`].join(' ')}>
      {children}
    </div>
  )
}
ScreenComponentItem.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['full', 'half', 'third', 'two-thirds'])
}
ScreenComponentItem.defaultProps = {
  size: 'full'
}
