import './InputComponent.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { labelDatetimeInput, labelDateInput } from '../libs/labelsLib'

export const InputPropTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
}

////////////////////////////////////////////////////////////////////////////////

export function InputTextComponent(props) {
  const { name, value, onChange, required, placeholder, type, className } = props

  return (
    <input className={['c-input', `c-input--${type}`, className].join(' ')} type="text" name={name} value={value} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder} />
  )
}
InputTextComponent.propTypes = { ...InputPropTypes }

////////////////////////////////////////////////////////////////////////////////

export function InputEmailComponent(props) {
  const { name, value, onChange, required, placeholder, type, className } = props

  return (
    <input className={['c-input', `c-input--${type}`, className].join(' ')} type="email" name={name} value={value} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder} />
  )
}
InputTextComponent.propTypes = { ...InputPropTypes }

////////////////////////////////////////////////////////////////////////////////

export function InputTextareaComponent(props) {
  const { name, value, onChange, required, placeholder, type, rows, className } = props

  return (
    <textarea className={['c-input', `c-input--${type}`, className].join(' ')} name={name} value={value} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder} rows={rows} />
  )
}
InputTextareaComponent.propTypes = {
  ...InputPropTypes,
  rows: PropTypes.number
}
InputTextareaComponent.defaultProps = {
  rows: 5,
  className: ''
}

////////////////////////////////////////////////////////////////////////////////

export function InputPasswordComponent(props) {
  const { name, value, onChange, required, placeholder, type, className } = props
  const [visible, setVisible] = useState(false)

  // TODO: Attivare pulsante per visualizzare contenuto input.

  return (
    <div style={{ position: 'relative' }}>
      <input className={['c-input', `c-input--${type}`, className].join(' ')} type={visible ? 'text' : 'password'} name={name} value={value} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder} />
    </div>
  )
}
InputPasswordComponent.propTypes = { ...InputPropTypes }

////////////////////////////////////////////////////////////////////////////////

export function InputDateTimeComponent(props) {
  const { name, value, onChange, required, placeholder } = props

  return (
    <input className='c-input' type="datetime-local" name={name} value={labelDatetimeInput(value)} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder} />
  )
}
InputDateTimeComponent.propTypes = { ...InputPropTypes }

////////////////////////////////////////////////////////////////////////////////

export function InputDateComponent(props) {
  const { name, value, onChange, required, placeholder } = props

  return (
    <input className='c-input' type="date" name={name} value={labelDateInput(value)} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder} />
  )
}
InputDateComponent.propTypes = { ...InputPropTypes }

////////////////////////////////////////////////////////////////////////////////

export function InputFileComponent(props) {
  const { name, value, onChange, required, placeholder } = props

  return (
    <input className='c-input' type="file" name={name} onChange={(e) => onChange(e.target.files[0])} required={required} placeholder={placeholder} />
  )
}
InputFileComponent.propTypes = { ...InputPropTypes }

////////////////////////////////////////////////////////////////////////////////

export function InputSelectComponent(props) {
  const { name, value, onChange, required, placeholder, options } = props

  return (
    <select className='c-input' value={value} name={name} onChange={(e) => onChange(e.target.value)} required={required} placeholder={placeholder}>
      {options.map((option, index) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  )
}
InputSelectComponent.propTypes = {
  ...InputPropTypes,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired
  }))
}
InputSelectComponent.defaultProps = {
  options: []
}