/**
 * 
 * @param {*} value 
 * @param {*} singular 
 * @param {*} plural 
 * @returns 
 */
export function labelPerValue(value, singular, plural) {
  return value === 1 ? singular : plural
}

/**
 * 
 * @param {*} time 
 * @returns 
 */
export function labelTimeAgo(time) {
  const rangeSeconds = (date1, date2) => {
    const range = date2 - date1
    return range / 1000
  }

  const endDate = new Date()
  const nowDate = new Date(time)
  const secondsDiff = rangeSeconds(nowDate, endDate)

  let val = null
  let unit = null

  if (secondsDiff < 3600) {
    val = secondsDiff / 60
    unit = val > 1 ? 'minuti fa' : 'minuto fa'
  } else if (secondsDiff < 86400) {
    val = secondsDiff / 3600
    unit = val > 1 ? 'ore fa' : 'ora fa'
  } else if (secondsDiff < 2592000) {
    val = secondsDiff / 86400
    unit = val > 1 ? 'giorni fa' : 'giorno fa'
  } else {
    val = secondsDiff / 2592000
    unit = val > 1 ? 'mesi fa' : 'mese fa'
  }

  const parseval = parseInt(val)
  if (parseval > 0) {
    return `${parseInt(val)} ${unit}`
  } else {
    return `meno di un ${unit}`
  }
}

/**
 * 
 * @param {*} time 
 * @returns 
 */
export function labelTimeLeft(time) {
  const rangeSeconds = (date1, date2) => {
    const range = date2 - date1
    return range / 1000
  }

  const endDate = new Date(time)
  const nowDate = new Date()
  const secondsDiff = rangeSeconds(nowDate, endDate)

  let val = null
  let unit = null

  if (secondsDiff < 3600) {
    val = secondsDiff / 60
    unit = val > 1 ? 'minuti rimanenti' : 'minuto rimanente'
  } else if (secondsDiff < 86400) {
    val = secondsDiff / 3600
    unit = val > 1 ? 'ore rimanenti' : 'ora rimanente'
  } else {
    val = secondsDiff / 86400
    unit = val > 1 ? 'giorni rimanenti' : 'giorno rimanente'
  }

  const parseval = parseInt(val)
  if (parseval > 0) {
    return `${parseInt(val)} ${unit}`
  } else {
    return `meno di un ${unit}`
  }
}

/**
 * 
 * @param {*} label 
 * @param {*} limit 
 * @returns 
 */
export function labelLimited(label, limit) {
  if (!label) return ''

  if (label.length > limit) {
    return `${label.substring(0, limit)}...`
  } else {
    return label
  }
}

/**
 * 
 * @param {*} datetime 
 * @returns 
 */
export function labelDatetimeInput(datetime) {
  if (!datetime) return ''

  const normalize = (num) => num > 9 ? num : `0${num}`
  const normalizeYear = (num) => num > 999 ? num : (num > 99 ? `0${num}` : (num > 9 ? `00${num}` : `000${num}`))

  const data = new Date(datetime)
  const day = normalize(data.getDate())
  const month = normalize(data.getMonth() + 1)
  const year = normalizeYear(data.getFullYear())
  const hours = normalize(data.getHours())
  const minutes = normalize(data.getMinutes())

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export function labelDateInput(date) {
  if (!date) return ''

  const normalize = (num) => num > 9 ? num : `0${num}`
  const normalizeYear = (num) => num > 999 ? num : (num > 99 ? `0${num}` : (num > 9 ? `00${num}` : `000${num}`))

  const data = new Date(date)
  const day = normalize(data.getDate())
  const month = normalize(data.getMonth() + 1)
  const year = normalizeYear(data.getFullYear())

  return `${year}-${month}-${day}`
}

/**
 * 
 * @param {*} date 
 * @returns 
 */
export function labelDate(date) {
  if (!date) return ''

  const normalize = (num) => num > 9 ? num : `0${num}`
  const normalizeYear = (num) => num > 999 ? num : (num > 99 ? `0${num}` : (num > 9 ? `00${num}` : `000${num}`))

  const data = new Date(date)
  const day = normalize(data.getDate())
  const month = normalize(data.getMonth() + 1)
  const year = normalizeYear(data.getFullYear())

  return `${day}/${month}/${year}`
}

/**
 * 
 * @param {*} label 
 * @returns 
 */
export function labelWithoutHTML(label) {
  return label.replace(/<(?:.|\n)*?>/gm, '')
}