import './TitleComponent.scss'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'
import { InputTextComponent, InputSelectComponent } from './InputComponent'
import { appUrl, appMobile } from '../libs/appLib'
import { debounce } from '../libs/utilsLib'
import ButtonComponent, { ButtonComponentPropTypes } from './ButtonComponent'
import SpacerComponent from './SpacerComponent'
import OverlayComponent from './OverlayComponent'

function TitleComponent(props) {
  const { title, subtitle, align, size, onBack, button, filters, onFiltersChange } = props

  return (
    <div className={['c-title', `c-title--${align}`, `c-title--${size}`].join(' ')}>
      <div className="c-title__main">
        {!!onBack && (
          <button className="back" onClick={onBack}>
            <ReactSVG src={`${appUrl()}/icons/back.svg`} />
          </button>
        )}
        <div className="content">
          <h1>{title}</h1>
          {!!subtitle && <p>{subtitle}</p>}
        </div>
      </div>
      {!!button && (
        <div className="c-title__button">
          <ButtonComponent {...button} size='small' />
        </div>
      )}
      {!!filters && (
        <Filters filters={filters} onChange={onFiltersChange} />
      )}
    </div>
  )
}
export const TitleComponentPropTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center']),
  size: PropTypes.oneOf(['small', 'medium']),
  onBack: PropTypes.func,
  button: PropTypes.shape(ButtonComponentPropTypes),
  filters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['select', 'search']),
  })),
  onFiltersChange: PropTypes.func,
}
TitleComponent.propTypes = TitleComponentPropTypes
TitleComponent.defaultProps = {
  align: 'left',
  size: 'medium',
  onFiltersChange: () => null,
}

export default TitleComponent

////////////////////////////////////////////////////////////////////////////////

function Filters(props) {
  const { filters, onChange } = props
  const [values, setValues] = useState({})
  const [isCollapsable, setIsCollapsable] = useState(appMobile() || false)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const container = useRef(null)

  useEffect(() => {
    if (appMobile()) return

    if (container.current.offsetWidth < 500 && filters.length > 2) {
      setIsCollapsable(true)
    }
  }, [])

  useEffect(() => {
    if (isCollapsable || !values) return
    onChangeDebounce(values)
  }, [values])

  const onChangeDebounce = useCallback(debounce((values) => {
    onChange(values)
  }, 500), [])

  const onChangeFilter = (name, value) => {
    if (value) {
      setValues({ ...values, [name]: value })
    } else {
      const newValues = { ...values }
      delete newValues[name]
      setValues(newValues)
    }
  }

  const onConfirm = () => {
    if (values) onChange(values)
    setIsCollapsed(false)
  }

  const ContentContainer = isCollapsable ? OverlayComponent : 'div'

  return (
    <div className="c-title__filters" ref={container}>
      {isCollapsable && (
        <div className="c-title__filters-toggles">
          <ButtonComponent label="Filtri" size='small' onClick={() => setIsCollapsed(true)} />
          {Object.values(values).length > 0 && (
            <ButtonComponent label="Reset filtri" type="alert" size='small' onClick={() => setValues({})} />
          )}
        </div>
      )}

      <ContentContainer
        open={isCollapsed}
        padded={isCollapsable || 'none'}
      >
        <div className={['c-title__filters-content', isCollapsable ? 'c-title__filters-content--collapsable' : ''].join(' ')}>
          <div className="filters">
            {filters.map(filter => (
              <div key={filter.name} className="filter">
                <Filter {...filter} value={values[filter.name] || ''} onChange={(value) => onChangeFilter(filter.name, value)} />
              </div>
            ))}
          </div>
          {!isCollapsable && Object.values(values).length > 0 && (
            <div className="actions">
              <ButtonComponent label="Reset filtri" type="alert" onClick={() => setValues({})} />
            </div>
          )}
          {isCollapsable && (
            <div className="actions">
              <ButtonComponent label="Applica filtri" block onClick={onConfirm} />
              <SpacerComponent size='small' />
              <ButtonComponent label="Annulla" type="alert" block onClick={() => setIsCollapsed(false)} />
            </div>
          )}
        </div>
      </ContentContainer>
    </div>
  )
}

function Filter(props) {
  const { name, label, value, type, onChange, options } = props

  return (
    <div className="filter">
      {type == 'search' && (
        <InputTextComponent name={name} placeholder={label} value={value} onChange={onChange} />
      )}
      {type == 'select' && (
        <InputSelectComponent name={name} placeholder={label} value={value} onChange={onChange} options={[{ label, value: '' }].concat(options)} />
      )}
    </div>
  )
}
