import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { AppProvider } from 'contexts/AppContext'
import { FlashProvider } from 'contexts/FlashContext'

import IndexRinascenteScreen from '../screens/IndexRinascenteScreen'
import LoginRinascenteScreen from '../screens/LoginRinascenteScreen'

import FlashInteractive from 'interactives/FlashInteractive'
import BallsComponent from 'components/BallsComponent'

export default (props) => {
  const { views } = props

  return (
    <>
      <BallsComponent type='gray' fixed />
      <main>
        <AppProvider app='authentication' views={views}>
          <FlashProvider>
            <Router>
              <FlashInteractive />

              <Route path='/authentication' component={IndexRinascenteScreen} exact />
              <Route path='/authentication/login' component={LoginRinascenteScreen} exact />
            </Router>
          </FlashProvider>
        </AppProvider>
      </main>
    </>
  )
}