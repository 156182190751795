import React, { useState, createContext } from 'react'

export const FlashContext = createContext()

const defaultFlash = {
  open: false,
  title: '...',
  message: null,
  button: null,
  type: 'primary'
}

export function FlashProvider(props) {
  const { children } = props
  const [state, setState] = useState(defaultFlash)

  const setFlash = (title, message, button = { label: 'Chiudi', onClick: closeFlash }, type = 'primary') => {
    setState({ ...state, open: true, title, message, button, type })
  }

  const closeFlash = () => {
    setState({ ...state, ...defaultFlash })
  }

  return (
    <FlashContext.Provider value={{ flash: state, setFlash, closeFlash }}>
      {children}
    </FlashContext.Provider>
  )
}