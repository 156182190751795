import './CardComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ButtonComponent, { ButtonComponentPropTypes } from './ButtonComponent'

export default function CardComponent(props) {
  const { title, children, button, style } = props

  const hasHeader = !!title || !!button

  return (
    <div className="c-card" style={style}>
      {hasHeader && <div className="c-card__header">
        {!!title && (
          <div className="title">
            {title}
          </div>
        )}
        {!!button && (
          <div className="button">
            <ButtonComponent {...button} size='small' />
          </div>
        )}
      </div>}
      {children}
    </div>
  )
}
CardComponent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.any,
  button: PropTypes.shape(ButtonComponentPropTypes),
  style: PropTypes.object,
}
CardComponent.defaultProps = {
  style: {}
}
