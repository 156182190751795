import React, { useContext, useMemo } from 'react'
import { AppContext } from 'contexts/AppContext'

import FormInteractive from 'interactives/FormInteractive'
import LinkInteractive from 'interactives/LinkInteractive'

import ScreenComponent, { ScreenComponentItem } from 'components/ScreenComponent'
import CardComponent from 'components/CardComponent'
import AuthenticationLogoComponent from 'components/AuthenticationLogoComponent'
import { InputEmailComponent, InputPasswordComponent } from 'components/InputComponent'
import SpacerComponent from 'components/SpacerComponent'

export default function LoginScreen(props) {
  const { views } = useContext(AppContext)

  const customRedirectFromUrl = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('ru')
  }, [])

  return (
    <ScreenComponent fullscreen center>
      <ScreenComponentItem size='full'>
        <AuthenticationLogoComponent src={views.logo_url} />
      </ScreenComponentItem>
      <ScreenComponentItem size='third'>
        <CardComponent>
          <FormInteractive
            path='/apis/authentication/login'
            inputs={[
              { name: 'email', label: 'Indirizzo email', component: InputEmailComponent, required: true },
              { name: 'password', label: 'Password', component: InputPasswordComponent, required: true },
            ]}
            submitLabel='Accedi'
            onSuccess={() => window.location.assign(customRedirectFromUrl || '/')}
          />

          <SpacerComponent />

          <div style={{ fontSize: 12, textAlign: 'center' }}>
            <LinkInteractive
              path='/authentication/recover-password-request'
            >Recupera password</LinkInteractive>
            &ensp;-&ensp;
            <LinkInteractive
              path='/authentication/link-request'
            >Accedi tramite link</LinkInteractive>
          </div>
        </CardComponent>
      </ScreenComponentItem>
    </ScreenComponent>
  )
}
