/**
 * 
 * @param {*} path 
 * @param {*} params 
 * @returns 
 */
export function getRequest(path, params = {}) {
  let url = `${path}?`

  if (params) {
    Object.keys(params).forEach((paramKey) => {
      if (params[paramKey] == null || params[paramKey] == undefined) return
      url = `${url}&${paramKey}=${params[paramKey]}`
    })
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then((response) => response.json())
    .then((responseJson) => resolve(responseJson))
    .catch((error) => {
      console.log(error)
      resolve({ result: false, error: "There's a network error" })
    })
  })
}

/**
 * 
 * @param {*} path 
 * @param {*} params 
 * @param {*} multipart 
 * @returns 
 */
export function postRequest(path, params, multipart = false) {
  const url = `${path}`

  const headers = {
    'Accept': 'application/json',
    'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
  if (!multipart) {
    headers['Content-Type'] = 'application/json'
  }

  const body = multipart ? new FormData() : JSON.stringify(params)
  if (multipart) Object.keys(params).map((key) => { body.append(key, params[key]) })

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers,
      body
    })
    .then((response) => response.json())
    .then((responseJson) => resolve(responseJson))
    .catch((error) => {
      console.log(error)
      resolve({ result: false, error: "There's a network error" })
    })
  })
}

/**
 * 
 * @param {*} path 
 * @param {*} params 
 * @param {*} filename 
 * @returns 
 */
export function fileRequest(path, params = {}, filename = 'file') {
  let url = `${path}?`

  if (params) {
    Object.keys(params).forEach((paramKey) => {
      url = `${url}&${paramKey}=${params[paramKey]}`
    })
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'x-csrf-token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then((response) => {
      if (response.ok) {
        return response.blob()
      } else {
        throw new Error('Something went wrong')
      }
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click() 
      a.remove()
      resolve(true)
    })
    .catch((error) => {
      console.log(error)
      resolve(false)
    })
  })
}