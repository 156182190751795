import React, { useEffect, useContext, useState } from 'react'
import { AppContext } from 'contexts/AppContext'
import { FlashContext } from 'contexts/FlashContext'
import ScreenComponent, { ScreenComponentItem } from 'components/ScreenComponent'
import CardComponent from 'components/CardComponent'
import AuthenticationLogoComponent from 'components/AuthenticationLogoComponent'
import FormInteractive from 'interactives/FormInteractive'
import LinkInteractive from 'interactives/LinkInteractive'
import { InputPasswordComponent } from 'components/InputComponent'
import SpacerComponent from 'components/SpacerComponent'
import { postRequest } from 'libs/requestsLib'

export default function RecoverPasswordUpdateScreen(props) {
  const { views } = useContext(AppContext)
  const { setFlash } = useContext(FlashContext)
  const [code, setCode] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    setCode(urlParams.get('c'))
  }, [])

  useEffect(() => {
    if (!code) return

    postRequest('/apis/authentication/recover-password-validate', { code }).then((response) => {
      if (!response.result) {
        setFlash('Attenzione', response.error, { label: 'Va bene', onClick: () => window.location.assign('/authentication/login') }, 'secondary')
      }
    })
  }, [code])

  const onSuccess= () => {
    return {
      title: 'Password aggiornata',
      message: "Da questo momento potrai accedere a FeedBack con le nuova password.",
      button: { label: 'Vai al login', onClick: () => window.location.assign('/authentication/login') }
    }
  }

  return (
    <ScreenComponent fullscreen center>
      <ScreenComponentItem size='full'>
        <AuthenticationLogoComponent src={views.logo_url} />
      </ScreenComponentItem>
      <ScreenComponentItem size='third'>
        <CardComponent>
          <FormInteractive
            path='/apis/authentication/recover-password-update'
            inputs={[
              { name: 'password', label: 'Nuova password', component: InputPasswordComponent, required: true },
              { name: 'password_confirmation', label: 'Ripeti la nuova password', component: InputPasswordComponent, required: true }
            ]}
            onSuccess={onSuccess}
            forcedParams={{ code }}
          />

          <SpacerComponent />

          <div style={{ fontSize: 12, textAlign: 'center' }}>
            <LinkInteractive
              path='/authentication/login'
            >Torna al login</LinkInteractive>
          </div>
        </CardComponent>
      </ScreenComponentItem>
    </ScreenComponent>
  )
}
