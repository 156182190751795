import './ButtonListComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ButtonComponent, { ButtonComponentPropTypes } from './ButtonComponent'

export default function ButtonListComponent(props) {
  const { buttonProps } = props

  return (
    <ul className="c-button-list">
      {buttonProps.map((buttonProps, index) => (
        <li key={index}>
          <ButtonComponent {...buttonProps} />
        </li>
      ))}
    </ul>
  )
}
ButtonListComponent.propTypes = {
  buttonProps: PropTypes.arrayOf(PropTypes.shape(ButtonComponentPropTypes))
}
ButtonListComponent.defaultProps = {
  buttonProps: []
}
