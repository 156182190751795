import './FlashComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'
import BallsComponent from './BallsComponent'
import ButtonComponent, { ButtonComponentPropTypes } from './ButtonComponent'
import OverlayComponent from './OverlayComponent'

export default function FlashComponent(props) {
  const { open, title, message, type, button } = props

  return (
    <OverlayComponent open={open} full>
      <div className={["c-flash", `c-flash--${type}`].join(' ')}>
        <BallsComponent type={type} />
        <div className='c-flash__title'>{title}</div>
        {!!message && <div className='c-flash__message'>{message}</div>}
        {!!button && <ButtonComponent {...button} className='c-flash__button' type='white' size='large' />}
      </div>
    </OverlayComponent>
  )
}
FlashComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  button: PropTypes.shape(ButtonComponentPropTypes)
}
FlashComponent.defaultProps = {
  type: 'primary'
}