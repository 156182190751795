import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { AppProvider } from 'contexts/AppContext'
import { FlashProvider } from 'contexts/FlashContext'

import IndexScreen from '../screens/IndexScreen'
import SignupScreen from '../screens/SignupScreen'
import LoginScreen from '../screens/LoginScreen'
import RecoverPasswordRequestScreen from '../screens/RecoverPasswordRequestScreen'
import RecoverPasswordUpdateScreen from '../screens/RecoverPasswordUpdateScreen'
import LinkRequestScreen from '../screens/LinkRequestScreen'

import FlashInteractive from 'interactives/FlashInteractive'
import BallsComponent from 'components/BallsComponent'

export default (props) => {
  const { views } = props

  return (
    <>
      <BallsComponent type='gray' fixed />
      <main>
        <AppProvider app='authentication' views={views}>
          <FlashProvider>
            <Router>
              <FlashInteractive />

              <Route path='/authentication' component={IndexScreen} exact />
              <Route path='/authentication/signup' component={SignupScreen} exact />
              <Route path='/authentication/login' component={LoginScreen} exact />
              <Route path='/authentication/link-request' component={LinkRequestScreen} exact />
              <Route path='/authentication/recover-password-request' component={RecoverPasswordRequestScreen} exact />
              <Route path='/authentication/recover-password-update' component={RecoverPasswordUpdateScreen} exact />
            </Router>
          </FlashProvider>
        </AppProvider>
      </main>
    </>
  )
}