import React, { useContext, useEffect } from 'react'
import { AppContext } from 'contexts/AppContext'
import ScreenComponent, { ScreenComponentItem } from 'components/ScreenComponent'
import AuthenticationLogoComponent from 'components/AuthenticationLogoComponent'
import CardComponent from 'components/CardComponent'
import SpacerComponent from 'components/SpacerComponent'
import ButtonListComponent from 'components/ButtonListComponent'
import TitleComponent from 'components/TitleComponent'
import { postRequest } from 'libs/requestsLib'

export default function IndexRinascenteScreen(props) {
  const { history } = props
  const { views } = useContext(AppContext)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('c')
    if (!code) return

    postRequest('/apis/authentication/link-login', { code }).then((response) => {
      if (!response.result) {
        setFlash('Attenzione', response.error, { label: 'Va bene', onClick: () => window.location.assign('/authentication/login') }, 'secondary')
      }

      window.location.assign('/')
    })
  }, [])

  return (
    <ScreenComponent fullscreen center>
      <ScreenComponentItem size='full'>
        <AuthenticationLogoComponent src={views.logo_url} />
      </ScreenComponentItem>
      <ScreenComponentItem size='third'>
        <CardComponent>
          <TitleComponent
            title='Benvenuto in FeedBack'
            subtitle="Cosa vuoi fare?"
            align='center'
          />
          <SpacerComponent />
          <ButtonListComponent
            buttonProps={[
              { label: 'Accedere', onClick: () => history.push('/authentication/login'), block: true },
              { label: "Cos'è FeedBack?", onClick: () => window.location.assign('https://feed-back.it'), type: 'white', block: true },
            ]}
          />
        </CardComponent>
      </ScreenComponentItem>
    </ScreenComponent>
  )
}
