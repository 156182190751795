import './OverlayComponent.scss'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { disableScroll, enableScroll } from '../libs/utilsLib'
export default function OverlayComponent(props) {
  const { children, open, padded, full } = props

  useEffect(() => {
    return () => enableScroll()
  }, [])

  useEffect(() => {
    if (open) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [open])

  return (
    <div className={["c-overlay", open ? 'is-open' : '', padded ? 'is-padded' : '', full ? 'is-full' : ''].join(' ')}>
      <div className="c-overlay__bg" />
      <div className="c-overlay__content">
        {children}
      </div>
    </div>
  )
}
OverlayComponent.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  padded: PropTypes.bool,
  full: PropTypes.bool,
}