import React, { useContext } from 'react'
import FlashComponent  from 'components/FlashComponent'
import { FlashContext } from 'contexts/FlashContext'

export default function FlashInteractive(props) {
  const { flash } = useContext(FlashContext)

  return (
    <FlashComponent
      open={flash.open}
      title={flash.title}
      message={flash.message}
      button={flash.button}
      type={flash.type}
    />
  )
}