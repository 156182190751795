/**
 * 
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
export function debounce(fn, delay) {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * 
 * @returns 
 */
export function randId() {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}

/**
 * 
 * @param {*} color 
 * @param {*} percent 
 * @returns 
 */
export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;  
  G = (G<255)?G:255;  
  B = (B<255)?B:255;  

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}

/**
 * 
 */
export function disableScroll() {
  const x = window.scrollX
  const y = window.scrollY
  window.onscroll = () => window.scrollTo(x, y)
}

/**
 * 
 */
export function enableScroll() {
  window.onscroll = () => {}
}

/**
 * 
 * @param {*} text 
 * @returns 
 */
export function copyTextToClipboard(text, callback = {}) {
  function fallbackCopyTextToClipboard() {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      callback()
    } catch (err) {
      callback(err)
    }
  
    document.body.removeChild(textArea);
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard();
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    callback()
  }, function(err) {
    callback(err)
  });
}
