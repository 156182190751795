import './BallsComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'

export default function BallsComponent(props) {
  const { fixed, type } = props

  return (
    <div className={["c-balls", `c-balls--${type}`, fixed ? "is-fixed" : ''].join(' ')}>
      <div className="c-balls__ball c-balls__first-ball"></div>
      <div className="c-balls__ball c-balls__second-ball"></div>
      <div className="c-balls__ball c-balls__third-ball"></div>
      <div className="c-balls__ball c-balls__fourth-ball"></div>
    </div>
  )
}
BallsComponent.propTypes = {
  fixed: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'gray'])
}
BallsComponent.defaultProps = {
  type: 'primary'
}