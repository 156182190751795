import React, { createContext } from 'react'
import PropTypes from 'prop-types'

export const AppContext = createContext()

export function AppProvider(props) {
  const { app, views, user, customProps, children } = props

  return (
    <AppContext.Provider value={{ app, views, user, customProps }}>
      {children}
    </AppContext.Provider>
  )
}
AppProvider.propTypes = {
  app: PropTypes.string.isRequired,
  views: PropTypes.object.isRequired,
  user: PropTypes.object
}

export function withAppContext(Component) {
  class ComponentWithContext extends React.Component {
    render () {
      return (
        <AppContext.Consumer>
          {(value) => <Component {...this.props} provider={value} />}
        </AppContext.Consumer>
      )
    }
  }

  return ComponentWithContext
}
